export const emailCheck = (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/);
// export const dateCheck = (/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
export const dateCheck = (/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/);
export const badLogin = 'badLogin';
export const badPassword = 'badPassword';
export const signInSuccess = 'signInSuccess';

export const secretName = 'tonya';

export const teal = '#009999';
export const yellow = '#ffcc00';
